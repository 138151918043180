html,
body,
body > div:first-child,
#__next,
#__next > div,
#__next > div > div {
  height: 100%;
}

/** Prevent a blank-blink until normarise.css is loaded complete */
html,
body {
  margin: 0;
  padding: 0;
}

.title-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  white-space: normal;
  -webkit-line-clamp: 1;
  word-break: break-all;
}
.title-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  white-space: normal;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
